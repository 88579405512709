import authReducer from "@src/redux/reducers/auth";
import { WebStorageStateStore, InMemoryWebStorage, User } from "oidc-client-ts";
import { setCookie, removeCookie } from "typescript-cookie";

const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENTID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    silent_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSETYPE,
    scope: process.env.REACT_APP_SCOPE,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    onSigninCallback: (user: User) => {
        if (user) {
            const tenant: any = user.profile.tenant;
            console.log('TENANT')
            console.log(tenant);
            // setCookie("tenant", tenant, {
            //     path: "",
            //     domain: process.env.REACT_APP_COOKIE_DOMAIN,
            //     expires: user.expires_at
            // });

            setCookie("prosoft.acr_values", "tenant:" + tenant, {
                path: "",
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
                expires:  new Date().getDate() + 30
            });
            //document.cookie = "tenant=" + user.profile.tenant + ";max-age=" + user.expires_in;
        }
    }
    // onRemoveUser: () => {
    //     console.log('delete cookie');
    //     removeCookie("prosoft.acr_values", {
    //         path: "",
    //         domain: process.env.REACT_APP_COOKIE_DOMAIN
    //     });
    //     //document.cookie = "tenant=;max-age=0"
    // }
}

export default oidcConfig